import React, {useEffect} from 'react'

const Logout = () => {
  useEffect(()=>{
    console.log('Logging out');
    localStorage.clear();
    window.location.href='/login';
  },[]);
}
export default Logout;
